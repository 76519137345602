<div class="btn-group" data-toggle="buttons">
    <button *ngIf="isToShow(viewModeEnum.ListElement)"
       routerLink="."
       [queryParams]="{view: 'list'}"
       queryParamsHandling="merge"
       (click)="switchViewTo(viewModeEnum.ListElement)"
       routerLinkActive="active"
       [class.active]="currentMode === viewModeEnum.ListElement"
       class="btn btn-secondary"
       [attr.data-test]="'list-view' | dsBrowserOnly">
       <span class="fas fa-list"></span><span class="sr-only">{{'search.view-switch.show-list' | translate}}</span>
    </button>
    <button *ngIf="isToShow(viewModeEnum.GridElement)"
       routerLink="."
       [queryParams]="{view: 'grid'}"
       queryParamsHandling="merge"
       (click)="switchViewTo(viewModeEnum.GridElement)"
       routerLinkActive="active"
       [class.active]="currentMode === viewModeEnum.GridElement"
       class="btn btn-secondary"
       [attr.data-test]="'grid-view' | dsBrowserOnly">
       <span class="fas fa-th-large"></span><span class="sr-only">{{'search.view-switch.show-grid' | translate}}</span>
    </button>
    <button *ngIf="isToShow(viewModeEnum.DetailedListElement)"
       routerLink="."
       [queryParams]="{view: 'detailed'}"
       queryParamsHandling="merge"
       (click)="switchViewTo(viewModeEnum.DetailedListElement)"
       routerLinkActive="active"
       [class.active]="currentMode === viewModeEnum.DetailedListElement"
       class="btn btn-secondary"
       [attr.data-test]="'detail-view' | dsBrowserOnly">
      <span class="far fa-square"></span><span class="sr-only">{{'search.view-switch.show-detail' | translate}}</span>
    </button>
</div>
