<ngb-accordion #acc="ngbAccordion" [activeIds]="'settings'">
  <ngb-panel [id]="'settings'">
    <ng-template ngbPanelHeader>
      <div class="w-100 d-flex justify-content-between collapse-toggle" ngbPanelToggle (click)="acc.toggle('settings')" data-test="settings">
        <button type="button" class="btn btn-link p-0" (click)="$event.preventDefault()" [attr.aria-expanded]="!acc.isExpanded('browse')"
                aria-controls="collapsePanels">
          {{ 'admin.access-control.bulk-access-settings.header' | translate }}
        </button>
        <div class="text-right d-flex">
          <div class="ml-3 d-inline-block">
            <span *ngIf="acc.isExpanded('settings')" class="fas fa-chevron-up fa-fw"></span>
            <span *ngIf="!acc.isExpanded('settings')" class="fas fa-chevron-down fa-fw"></span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template ngbPanelContent>
      <ds-access-control-form-container #dsAccessControlForm [showSubmit]="false"></ds-access-control-form-container>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
